/* eslint-disable no-param-reassign */
/* linter rule not needed since redux toolkit uses Immer internally */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { LESSEE_REGISTER_PROPERTY_SLICE_NAME } from "store/constants";
import {
  BankAccountTypeEnum,
  BankEnum,
  CurrencyEnum,
  CategoryEnum,
  RegisterPayerPaymentDataInput,
} from "types";
import { getMonthDate } from "utils";

export type LesseeRegisterPropertyState = RegisterPayerPaymentDataInput & {
  category: CategoryEnum;
  amountStr?: string;
  month: {
    key: string;
    label: string;
  };
  cardId?: string;
  commissionAmount: number;
  discountAmount: number;
  totalAmount: number;
  payer: {
    tin: string;
    phone?: string;
  };
  propertyId?: string;
  cfUser?: boolean;
};

const initialState: LesseeRegisterPropertyState = {
  bankData: {
    bank: BankEnum.BancoBice,
    bankAccountNumber: "",
    bankAccountType: BankAccountTypeEnum.Current,
  },
  payee: {
    bankData: undefined,
    countryCode: undefined,
    email: undefined,
    name: "",
    payerId: undefined,
    tin: "",
    userId: undefined,
  },
  property: {
    communityAddress: "",
    communityName: "",
    name: "",
    nickname: undefined,
    payeeId: undefined,
    amount: 0,
    currency: CurrencyEnum.Clp,
  },
  category: CategoryEnum.Lease,
  month: {
    key: `${DateTime.now().month}`,
    label: getMonthDate(DateTime.now().month),
  },
  cardId: undefined,
  recurringInvoiceDayIndicator: new Date().getDate(),
  recurringInvoiceActive: true,
  amountStr: "",
  commissionAmount: 0,
  discountAmount: 0,
  totalAmount: 0,
  payer: {
    tin: "",
    phone: "",
  },
  propertyId: "",
  cfUser: false,
};

const registerPayerPaymentSlice = createSlice({
  name: LESSEE_REGISTER_PROPERTY_SLICE_NAME,
  initialState,
  reducers: {
    setCategory: (
      state,
      action: PayloadAction<LesseeRegisterPropertyState["category"]>
    ) => {
      state.category = action.payload;
    },
    setBankData: (
      state,
      action: PayloadAction<LesseeRegisterPropertyState["bankData"]>
    ) => {
      state.bankData = action.payload;
    },
    setPayee: (
      state,
      action: PayloadAction<LesseeRegisterPropertyState["payee"]>
    ) => {
      state.payee = action.payload;
    },
    setProperty: (
      state,
      action: PayloadAction<LesseeRegisterPropertyState["property"]>
    ) => {
      state.property = action.payload;
      state.property.name = action.payload.communityAddress;
    },
    setMonth: (
      state,
      action: PayloadAction<LesseeRegisterPropertyState["month"]>
    ) => {
      state.month = action.payload;
    },
    setPaymentData: (
      state,
      action: PayloadAction<LesseeRegisterPropertyState>
    ) => {
      state.bankData = action.payload.bankData;
      state.payee = action.payload.payee;
      state.property = action.payload.property;
      state.category = action.payload.category;
      state.month = action.payload.month;
    },
    setAmount: (state, action: PayloadAction<number>) => {
      state.property.amount = action.payload;
    },
    setAmountStr: (state, action: PayloadAction<string>) => {
      state.amountStr = action.payload;
    },
    setCardId: (state, action: PayloadAction<string>) => {
      state.cardId = action.payload;
    },
    setDayIndicator: (state, action: PayloadAction<number>) => {
      state.recurringInvoiceDayIndicator = action.payload;
    },
    setRecurringInvoiceActive: (state, action: PayloadAction<boolean>) => {
      state.recurringInvoiceActive = action.payload;
    },
    setCommissionAmount: (state, action: PayloadAction<number>) => {
      state.commissionAmount = action.payload;
    },
    setDiscountAmount: (state, action: PayloadAction<number>) => {
      state.discountAmount = action.payload;
    },
    setTotalAmount: (state, action: PayloadAction<number>) => {
      state.totalAmount = action.payload;
    },
    setPayerTin: (state, action: PayloadAction<string>) => {
      state.payer.tin = action.payload;
    },
    setPayerPhone: (state, action: PayloadAction<string>) => {
      state.payer.phone = action.payload;
    },
    setPropertyId: (state, action: PayloadAction<string>) => {
      state.propertyId = action.payload;
    },
    setCFUser: (state, action: PayloadAction<boolean>) => {
      state.cfUser = action.payload;
    },
    clearState: (state) => {
      state.bankData = initialState.bankData;
      state.payee = initialState.payee;
      state.property = initialState.property;
      state.category = initialState.category;
      state.month = initialState.month;
      state.amountStr = initialState.amountStr;
      state.commissionAmount = initialState.commissionAmount;
      state.discountAmount = initialState.discountAmount;
      state.totalAmount = initialState.totalAmount;
    },
  },
});

export const {
  setBankData,
  setPayee,
  setProperty,
  setCategory,
  setMonth,
  setPaymentData,
  setAmount,
  setAmountStr,
  setCardId,
  clearState,
  setDayIndicator,
  setRecurringInvoiceActive,
  setCommissionAmount,
  setDiscountAmount,
  setTotalAmount,
  setPayerTin,
  setPayerPhone,
  setPropertyId,
  setCFUser,
} = registerPayerPaymentSlice.actions;

export default registerPayerPaymentSlice;
